import React, { useState } from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box } from '@material-ui/core'
import { H3, MarkdownHandler } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const CustomerTypesModule = (props) => {
  const [overlaps, setOverlaps] = useState([])
  const [maxOverlap, setMaxOverlap] = useState(0)

  const useStyles = makeStyles((theme) => ({
    background: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      paddingBottom: 0,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: `${maxOverlap}px`,
      },
    },
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: '30px 0 16px',
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        margin: '0 auto',
        paddingTop: '50px',
      },
      display: 'block',
    },
    description: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        margin: '16px auto',
      },
    },
    cards: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
    },
  }))
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const updateMaxOverlap = (height) => {
    const arr = [...overlaps, height]
    setOverlaps(arr)
    setMaxOverlap(Math.max(...arr) + 16)
  }

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <Box className={classes.background}>
          <ContentContainer>
            <H3 className={classes.title}>{props.blok.title}</H3>
            {!!props.blok.description && (
              <MarkdownHandler className={classes.description}>
                {props.blok.description}
              </MarkdownHandler>
            )}
          </ContentContainer>
        </Box>
      </PageContainer>
      <PageContainer backgroundColor={xs ? 'paper' : 'default'}>
        <ContentContainer>
          <Box className={classes.cards}>
            {renderBloks(props.blok.customerTypeCards, { updateMaxOverlap })}
          </Box>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default CustomerTypesModule
